import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import ReactPlayer from "react-player";
import * as Icon from "./Icon";
import "../global-styles";

const Data = [
  {
    name: "MAPEX USA Artists Greeting Video | Official Trailer",
    url: "https://youtu.be/m2pQqzEdo-s"
  },
  {
    name: "MAPEX Europe Artists Greeting Video | Official Trailer",
    url: " https://youtu.be/mKGTsEiKnJw"
  },
  {
    name: "MAPEX Asia Pacific Artists Greeting Video | Official Trailer",
    url: "https://youtu.be/oeCPoPhaeQc"
  },
  {
    name: "MAPEX USA Artists Greeting Video Part 2 | Official Trailer",
    url: "https://youtu.be/n6A55bYlLRI"
  }
];

class GreetingVideoSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      slideIndex: 0
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    let { slideIndex } = this.state;

    return (
      <Wrapper id="video">
        <div className="title">ARTIST GREETING VIDEOS</div>
        <div className="container">
          <Carousel
            width={"100%"}
            height={this._resizeCarouselHeight()}
            height={"100%"}
            swiping={false}
            slideIndex={slideIndex}
            afterSlide={slideIndex => this.setState({ slideIndex })}
            renderBottomCenterControls={() => false}
            renderCenterLeftControls={this._renderLeftIcon}
            renderCenterRightControls={this._renderRightIcon}
          >
            {Data.map((d, i) => (
              <div className="video-item" key={i}>
                <ReactPlayer
                  url={d.url}
                  width={"80%"}
                  height={this._resizeVideoHeight()}
                />
                <div className="video-name">{d.name}</div>
              </div>
            ))}
          </Carousel>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <a
              href="https://www.youtube.com/playlist?list=PL_y7uN7ZO_TCP9a3tcG0-pEvnOAJygRmV"
              target="_blank"
              className="link"
            >
              Watch All Artists
            </a>
          </div>
        </div>
      </Wrapper>
    );
  }

  _renderLeftIcon = ({ previousSlide }) => {
    let { slideIndex } = this.state;

    return (
      <div style={{ cursor: "pointer" }} onClick={previousSlide}>
        <Icon.KeyboardArrowLeft
          color={slideIndex === 0 ? "#737373" : "white"}
          style={{ width: 40, height: 40 }}
        />
      </div>
    );
  };

  _renderRightIcon = ({ nextSlide }) => {
    let { slideIndex } = this.state;

    return (
      <div style={{ cursor: "pointer" }} onClick={nextSlide}>
        <Icon.KeyboardArrowRight
          color={slideIndex === 3 ? "#737373" : "white"}
          style={{ width: 40, height: 40 }}
        />
      </div>
    );
  };

  _resizeCarouselHeight = () => {
    let { mounted } = this.state;

    if (mounted) {
      if (window.screen.width <= 550) {
        return "350px";
      }

      return "600px";
    }
  };

  _resizeVideoHeight = () => {
    let { mounted } = this.state;

    if (mounted) {
      if (window.screen.width <= 550) {
        return "200px";
      }
    }

    return "524px";
  };
}

const Wrapper = styled.div`
  margin-top: 81px;
  margin-bottom: 50px;
  width: 100%;
  background-color: black;

  & > .title {
    padding: 80px 90px 20px;
    letter-spacing: 5px;
    text-align: center;
    font-family: BankGothicLHHeavy;
    font-size: 55px;
    color: #bcc400;
  }

  & > .container {
    margin: 0 auto;
    max-width: 80%;
    min-height: 600px;

    & .video-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 600px;
    }

    & .video-name {
      margin: 30px;
      width: 80%;
      white-space: pre-wrap;
      text-align: left;
      font-family: GothamMedium;
      font-size: 16px;
      color: white;
    }

    & .link {
      z-index: 1;
      position: relative;
      font-size: inherit;
      font-family: GothamMedium;
      color: white;
      margin-bottom: 30px;
      padding: 0.5em 1em;
      outline: none;
      border: none;
      background-color: black;
      overflow: hidden;
      transition: color 0.4s ease-in-out;
      border: 1px solid white;
      max-width: 200px;
      min-height: 50px;

      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-family: GothamMedium;
      font-size: 18px;
    }

    & .link::before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 100%;
      left: 100%;
      min-width: 192px;
      min-height: 50px;
      border-radius: 50%;
      background-color: white;
      transform-origin: center;
      transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
      transition: transform 0.45s ease-in-out;
    }

    & .link:hover {
      cursor: pointer;
      color: #161616;
    }

    & .link:hover::before {
      transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
    }
  }

  @media screen and (max-width: 768px) {
    & > .title {
      padding: 75px 60px 20px;
      letter-spacing: 0px;
      font-weight: 400;
      font-size: 44px;
    }

    & > .container {
      max-width: 100%;
      & .video-name {
        margin-top: 30px;
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width: 550px) {
    & > .container {
      min-height: 300px;
      & .video-item {
        min-height: 300px;
      }
    }
  }

  @media screen and (max-width: 375px) {
    & > .title {
      padding: 75px 30px 20px;
      font-size: 36px;
    }

    & > .container {
      & .video-name {
        margin-top: 20px;
        font-size: 16px;
      }
    }
  }
`;

export default GreetingVideoSection;
