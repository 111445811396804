import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "./Icon";

class QuizAnswerOption extends Component {
  render() {
    let { data, selected, onClick } = this.props;
    return (
      <Wrapper onClick={() => onClick(data)}>
        {selected === data ? (
          <Icon.RadioButtonChecked
            color="#bfc23e"
            style={{ width: 25, height: 25 }}
          />
        ) : (
          <Icon.RadioButtonUnChecked
            color="white"
            style={{ width: 25, height: 25 }}
          />
        )}
        <span>{data}</span>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;

  & > span {
    margin-left: 20px;
    max-width: 250px;
    width: 100%;
  }
`;

export default QuizAnswerOption;
