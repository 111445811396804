import React, { Component } from "react";
import styled from "styled-components";
import QuizImgAnswerOption from "./QuizImgAnswerOption";
import QuizAnswerOption from "./QuizAnswerOption";
import "../global-styles";

class QuizItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      error: ""
    };
  }

  render() {
    let {
      data,
      language,
      slideIndex,
      setLanguage,
      onClick,
      showAllAnswers
    } = this.props;
    let { selectedValue, error } = this.state;

    if (slideIndex === 0) {
      return (
        <ColWrapper>
          <h2>{language !== "tw" ? data.title_en : data.title_tw}</h2>
          <p>
            {language !== "tw" ? data.description_en : data.description_tw}
            <p style={{ fontSize: 10, marginTop: 10 }}>
              {language !== "tw" ? data.description2_en : data.description2_tw}
            </p>
          </p>
          <button
            onClick={() => {
              if (language === "") {
                return this.setState({ error: true });
              }
              return onClick();
            }}
          >
            START QUIZ
          </button>
          {error && (
            <div className="error">
              Please choose your language to continue!
            </div>
          )}
          <div className="lang">
            {language === "en" ? (
              <div
                style={{ color: "#BCC400" }}
                onClick={() => setLanguage("en")}
              >
                EN
              </div>
            ) : (
              <div onClick={() => setLanguage("en")}>EN</div>
            )}
            <div> | </div>
            {language === "tw" ? (
              <div
                style={{ color: "#BCC400" }}
                onClick={() => setLanguage("tw")}
              >
                中文
              </div>
            ) : (
              <div onClick={() => setLanguage("tw")}>中文</div>
            )}
          </div>
        </ColWrapper>
      );
    }

    if (slideIndex === 2) {
      return (
        <RowWrapper>
          <div className="left">
            <div className="number">QUESTION {slideIndex}</div>
            <div className="text">
              {language === "en" ? data.question_en : data.question_tw}
            </div>
          </div>
          <div className="line" />
          <div className="right">
            {this._setlanguage(data) &&
              this._setlanguage(data).map((d, i) => (
                <QuizImgAnswerOption
                  key={"quiz-answer-option" + i}
                  idx={i}
                  data={d}
                  selected={selectedValue}
                  onClick={this._updateAnswer}
                />
              ))}
            {slideIndex === 10 && selectedValue && (
              <button onClick={showAllAnswers}>END</button>
            )}
          </div>
        </RowWrapper>
      );
    }

    return (
      <RowWrapper>
        <div className="left">
          <div className="number">QUESTION {slideIndex}</div>
          <div className="text">
            {language === "en" ? data.question_en : data.question_tw}
          </div>
        </div>
        <div className="line" />
        <div className="right">
          {this._setlanguage(data) &&
            this._setlanguage(data).map((d, i) => (
              <QuizAnswerOption
                key={"quiz-answer-option" + i}
                data={d}
                selected={selectedValue}
                onClick={this._updateAnswer}
              />
            ))}
          {slideIndex === 10 && selectedValue && (
            <button onClick={showAllAnswers}>END</button>
          )}
        </div>
      </RowWrapper>
    );
  }

  _setlanguage = data => {
    let { language } = this.props;

    if (language === "en") {
      return data.options_en;
    }

    return data.options_tw;
  };

  _updateAnswer = value => {
    let { data, updateAnswer, slideIndex, currentMaxSlides } = this.props;
    let { selectedValue } = this.state;

    if (slideIndex !== currentMaxSlides) {
      return () => 0;
    }

    this.setState({ selectedValue: value });

    if (value === data.answer_en || value === data.answer_tw) {
      return updateAnswer(true, value);
    }

    return updateAnswer(false, value);
  };
}

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  height: 620px;

  & > h2 {
    margin-bottom: 20px;
    font-family: GothamMedium;
    font-size: 24px;
    color: white;
  }

  & > p {
    margin-bottom: 30px;
    white-space: pre-wrap;
    text-align: left;
    line-height: 20px;
    font-family: GothamMedium;
    font-size: 14px;
    color: white;
  }

  & > button {
    z-index: 1;
    position: relative;
    font-size: inherit;
    font-family: GothamMedium;
    color: white;
    margin-bottom: 30px;
    padding: 0.5em 1em;
    outline: none;
    border: none;
    background-color: black;
    overflow: hidden;
    transition: color 0.4s ease-in-out;
    border: 1px solid white;
    min-width: 192px;
    min-height: 50px;
  }

  & > button::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 100%;
    left: 100%;
    min-width: 192px;
    min-height: 50px;
    border-radius: 50%;
    background-color: white;
    transform-origin: center;
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
    transition: transform 0.45s ease-in-out;
  }

  & > button:hover {
    cursor: pointer;
    color: #161616;
  }

  & > button:hover::before {
    transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
  }

  & > .error {
    font-family: GothamMedium;
    font-size: 10px;
    color: #bcc400;
  }

  & > .lang {
    display: flex;
    justify-content: center;
    padding: 10px;

    & > div {
      min-width: 25px;
      text-align: center;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 900px) {
    & > p {
      margin-bottom: 10px;
      font-size: 12px;
    }

    & > button {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 570px) {
    height: 700px;
  }

  @media screen and (max-width: 400px) {
    & > h2 {
      margin-bottom: 0px;
    }
    & > p {
      line-height: 16px;
    }
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 620px;

  & > .left {
    flex: 1;
    color: white;
    padding-left: 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    & > .number {
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 28px;
    }

    & > .text {
      line-height: 30px;
      font-size: 22px;
    }
  }

  & > .line {
    margin: 0px 45px;
    width: 1px;
    height: 500px;
    background-color: white;
  }

  & > .right {
    flex: 1;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    & > button {
      z-index: 1;
      position: relative;
      font-size: inherit;
      font-family: GothamMedium;
      color: white;
      margin-bottom: 30px;
      padding: 0.5em 1em;
      outline: none;
      border: none;
      background-color: black;
      overflow: hidden;
      transition: color 0.4s ease-in-out;
      border: 1px solid white;
      min-width: 192px;
      min-height: 50px;
    }

    & > button::before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 100%;
      left: 100%;
      min-width: 192px;
      min-height: 50px;
      border-radius: 50%;
      background-color: white;
      transform-origin: center;
      transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
      transition: transform 0.45s ease-in-out;
    }

    & > button:hover {
      cursor: pointer;
      color: #161616;
    }

    & > button:hover::before {
      transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
    }
  }

  @media screen and (max-width: 700px) {
    height: 680px;
    & > .left {
      padding-left: 0px;
      & > .number {
        font-size: 26px;
      }
    }

    & > .line {
      margin: 0px 30px;
    }
  }

  @media screen and (max-width: 570px) {
    flex-direction: column;
    align-items: flex-start;
    & > .line {
      margin: 30px 0px;
      width: 100%;
      height: 1px;
    }
  }
`;

export default QuizItem;
