import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "./Icon";
import "../global-styles";

class ProductItem extends Component {
  constructor(props) {
    super(props);
    let { data } = this.props;
    this.state = {
      mounted: false,
      openDetail: false,
      displayImg: data.detailImg[1],
      displayText: data.detailText[0],
      mouseover: false,
      needTranslation: false
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    let { data, css } = this.props;
    let {
      openDetail,
      displayImg,
      displayText,
      mouseover,
      needTranslation
    } = this.state;
    this._checkScreenWidth();

    return (
      <>
        {mouseover ? (
          <Item
            onClick={() => {
              this._checkIndex();
              this.setState({ openDetail: true });
            }}
            onMouseLeave={() =>
              openDetail ? 0 : this.setState({ mouseover: false })
            }
            needTranslation={
              needTranslation &&
              `
                position: absolute;
                top: 0px;
                left: 0px;
              `
            }
            css={`
              background-image: url(/images/pages/products/${data.detailImg[0]});
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              height: 565px;
              @media screen and (max-width: 1000px) {
                height: 465px;
              }
              @media screen and (max-width: 790px) {
                height: 415px;
              }
              @media screen and (max-width: 600px) {
                height: 450px;
              }
            `}
          />
        ) : (
          <Item
            img={`../images/pages/products/${data.img}`}
            css={css}
            onMouseOver={() => this.setState({ mouseover: true })}
            onMouseLeave={() =>
              openDetail ? 0 : this.setState({ mouseover: false })
            }
            onClick={() => this.setState({ openDetail: true })}
          >
            <div className="graphic" />
            <div className="name">{data.name}</div>
            <button>LEARN MORE</button>
          </Item>
        )}

        {openDetail && (
          <Detail
            img={`/images/pages/products/${displayImg}`}
            needTranslation={needTranslation}
          >
            <div className="icons-row">
              <div
                className="close"
                onClick={() =>
                  this.setState({
                    openDetail: false,
                    displayImg: data.detailImg[1],
                    mouseover: false,
                    needTranslation: false
                  })
                }
              >
                {this._itemCol ? (
                  <Icon.ArrowUpward color="black" size={44} />
                ) : (
                  <Icon.ArrowBack color="black" size={44} />
                )}
              </div>
              <a className="link" target="_blank" href={data.detailLink}>
                Download PDF
              </a>
            </div>

            <div className="images-container">
              <div className="image-display" />
              <div className="images-box">
                {data.detailImg
                  .filter((_, i) => i > 0)
                  .map((d, i) => (
                    <Image
                      key={i}
                      img={`/images/pages/products/${d}`}
                      onClick={() =>
                        this.setState({
                          displayImg: d,
                          displayText: data.detailText[i]
                        })
                      }
                    />
                  ))}
              </div>
            </div>
            <div className="content-container">
              <p>{displayText}</p>
            </div>
          </Detail>
        )}
      </>
    );
  }

  _checkIndex = () => {
    let { index } = this.props;
    if (index === 0) {
      return;
    }

    if (index === 1) {
      return this.setState({ needTranslation: true });
    }
  };

  _checkScreenWidth = () => {
    let { mounted } = this.state;
    if (mounted) {
      let screenWidth = window.screen.width;

      if (screenWidth <= 565) {
        this._itemCol = true;
      }
    }
    return;
  };
}

const Image = styled.div`
  margin-bottom: 8px;
  border: 0.5px solid lightgray;
  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;
  width: 90px;
  height: 90px;

  @media screen and (max-width: 790px) {
    width: 60px;
    height: 60px;
  }
`;

const Detail = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  padding: 20px;
  width: calc(100% - 552px);
  height: 565px;
  background-color: white;

  display: flex;
  flex-direction: column;

  & > .content-container {
    max-height: 150px;
    overflow-y: auto;
    & > p {
      text-align: left;
      white-space: pre-line;
      font-family: GothamMedium;
      line-height: 1.2;
      font-size: 14px;
    }
  }

  & > .icons-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & > .images-container {
    display: flex;
    justify-content: center;
    align-items: center;

    & > .image-display {
      margin-right: 30px;
      max-width: 380px;
      width: 100%;
      height: 380px;

      background-image: url(${props => props.img});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    & > .images-box {
      margin-bottom: 15px;
      max-width: 90px;
      width: 100%;
      height: 286px;
      overflow-y: scroll;
    }
  }

  & .link {
    cursor: pointer;
    font-family: GothamBook;
    font-size: 12px;
    color: black;
  }

  & .close {
    cursor: pointer;
  }

  @media screen and (max-width: 1300px) {
    width: calc(100% - 450px);
  }

  @media screen and (max-width: 1000px) {
    width: calc(100% - 350px);
    height: 465px;
    & > .images-container {
      & > .image-display {
        height: 300px;
      }
    }
  }

  @media screen and (max-width: 790px) {
    width: calc(100% - 330px);
    height: 415px;
    & > .images-container {
      & > .image-display {
        margin-right: 15px;
        max-width: 300px;
        width: 100%;
        height: 210px;
      }

      & > .images-box {
        margin-bottom: 15px;
        max-width: 60px;
        width: 100%;
        height: 200px;
        overflow-y: scroll;
      }
    }
  }

  @media screen and (max-width: 730px) {
    width: calc(100% - 300px);
  }

  @media screen and (max-width: 660px) {
    width: calc(100% - 250px);
    height: 450px;
    & > .images-container {
      & > .image-display {
        margin-right: 30px;
        max-width: 400px;
        width: 100%;
        height: 250px;
      }
    }

    & > .close {
      right: 5px;
      bottom: 5px;
    }
  }

  @media screen and (max-width: 600px) {
    height: 450px;
  }

  @media screen and (max-width: 565px) {
    width: 100%;
    height: ${props =>
      props.needTranslation ? "450px" : `calc(100% - 450px);`};
    top: 450px;

    & > .images-container {
      & > .image-display {
        margin-right: 15px;
      }
    }

    & > .content-container {
      max-height: 125px;
    }
  }

  @media screen and (max-width: 414px) {
    & > .images-container {
      & > .image-display {
        max-width: 300px;
        width: 100%;
      }
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  padding: 30px 10px;
  max-width: 552px;
  width: 100%;
  height: 565px;
  background-color: white;

  ${props => props.needTranslation || ""};

  & > .graphic {
    background-image: url(${props => props.img});
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: center;

    margin-bottom: 5px;
    max-width: 400px;
    width: 100%;
    height: 400px;
  }

  & > .name {
    margin-bottom: 14px;
    font-family: GothamUltra;
    font-size: 25px;
  }

  & > button {
    border: 2px solid black;
    min-width: 194px;
    min-height: 50px;

    background-color: white;
    cursor: pointer;
    font-family: GothamMedium;
    font-size: 18px;

    :focus {
      outline: none;
    }
  }

  @media screen and (max-width: 1300px) {
    max-width: 450px;
  }

  @media screen and (max-width: 1000px) {
    max-width: 350px;
    height: 415px;

    & > .graphic {
      max-width: 300px;
      height: 300px;
    }
  }

  @media screen and (max-width: 790px) {
    max-width: 330px;
    height: 415px;

    & > .graphic {
      max-width: 250px;
      height: 250px;
    }
  }

  @media screen and (max-width: 730px) {
    max-width: 300px;
  }

  @media screen and (max-width: 660px) {
    max-width: 250px;
    min-height: 450px;

    & > .graphic {
      max-width: 200px;
      height: 200px;
    }
  }

  @media screen and (max-width: 600px) {
    height: 450px;
  }

  @media screen and (max-width: 565px) {
    max-width: 100%;
    & > .graphic {
      max-width: 300px;
      height: 300px;
    }
  }

  @media screen and (max-width: 375px) {
    & > .graphic {
      max-width: 250px;
      height: 250px;
    }
  }

  ${props => props.css || ""}
`;

export default ProductItem;
