import React from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import AnniversarySection from "../../Components/AnniversarySection";
import GreetingVideoSection from "../../Components/GreetingVideoSection";
import ProductSection from "../../Components/ProductSection";
import QuizSection from "../../Components/QuizSection";

class HomePage extends React.Component {
  render() {
    return (
      <Wrapper>
        <AnniversarySection />
        <div className="container">
          <GreetingVideoSection />
          <div className="share-bgImg">
            <ProductSection />
            <QuizSection />
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  background-color: black;

  & > .container {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;

    & > .share-bgImg {
      background-image: url("/images/bg-image01.png");
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat;
    }
  }
`;

export default withPage(HomePage);
