import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import { StaticFluidImage } from "./StaticImage";

const largeCarouselData = [
  { img1: "2019w.jpg", img2: "2019t.jpg" },
  { img1: "2018w.jpg", img2: "2018t.jpg" },
  { img1: "2014w.jpg", img2: "2014t.jpg" },
  { img1: "2012w.jpg", img2: "2012t.jpg" },
  { img1: "2010w.jpg", img2: "2010t.jpg" },
  { img1: "2009w.jpg", img2: "2009t.jpg" },
  { img1: "2002w.jpg", img2: "2002t.jpg" },
  { img1: "2000w.jpg", img2: "2000t.jpg" },
  { img1: "1997w.jpg", img2: "1997t.jpg" },
  { img1: "1992w.jpg", img2: "1992t.jpg" },
  { img1: "1989w.jpg", img2: "1989t.jpg" }
];

class AnniversaryLargeCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    let { slideIndex, afterSlide } = this.props;
    this.desktopDevice = true;

    return (
      <Carousel
        width={"100%"}
        height={"100%"}
        renderCenterLeftControls={() => false}
        renderCenterRightControls={() => false}
        renderBottomCenterControls={() => false}
        slideIndex={slideIndex}
        afterSlide={afterSlide}
      >
        {largeCarouselData.map(({ img1, img2 }, i) => {
          if (i === 0) {
            return (
              <LargeItem
                key={i}
                img={`/images/banner/${this._checkScreenSize() ? img1 : img2}`}
              />
            );
          }

          return (
            <StaticFluidImage
              key={i}
              path={`${this._checkScreenSize() ? img1 : img2}`}
              style={{ width: "100%", height: "100vh" }}
            />
          );
        })}
      </Carousel>
    );
  }

  _checkScreenSize = () => {
    let { mounted } = this.state;

    if (mounted) {
      if (window.screen.width <= 768) {
        this.desktopDevice = false;
      }
    }

    return this.desktopDevice;
  };
}

const LargeItem = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;

  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export default AnniversaryLargeCarousel;
