import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import Data from "../../content/bgstory.json";
import * as Icon from "./Icon";
import * as Widget from "./Widget2";
import "../global-styles";

class AnniversaryTabletSmallCarousel extends Component {
  render() {
    let {
      slideIndex,
      afterSlide,
      onLeftArrowClick,
      onRightArrowClick
    } = this.props;

    return (
      <Carousel
        width={"100%"}
        height={"420px"}
        wrapAround={true}
        swiping={false}
        slideIndex={slideIndex}
        afterSlide={afterSlide}
        renderCenterLeftControls={() => false}
        renderCenterRightControls={() => false}
        renderBottomCenterControls={() => false}
      >
        {Data.map(({ year, description }, i) => (
          <TabletCarouselItem key={i}>
            <Widget.Row align="center">
              <Widget.Col align="center">
                <Icon.KeyboardArrowLeft
                  color={"white"}
                  style={{ width: 20, height: 20 }}
                  onClick={() => onLeftArrowClick()}
                />
                <Hint>
                  {slideIndex === 0
                    ? Data[Data.length - 1].year
                    : Data[slideIndex - 1].year}
                </Hint>
              </Widget.Col>
              <h2 className="year">{year}</h2>
              <Widget.Col align="center">
                <Icon.KeyboardArrowRight
                  color={"white"}
                  style={{ width: 20, height: 20 }}
                  onClick={() => {
                    return onRightArrowClick();
                  }}
                />
                <Hint>
                  {slideIndex !== 10 ? Data[slideIndex + 1].year : Data[0].year}
                </Hint>
              </Widget.Col>
            </Widget.Row>

            {this._renderDots()}

            {slideIndex < 4 && (
              <img
                src={`/images/khs-logo.png`}
                style={{ width: 50, height: 45, marginBottom: 20 }}
              />
            )}
            {slideIndex >= 4 && slideIndex <= 6 && (
              <img
                src={`/images/khs-logo03.png`}
                style={{ width: 127, height: 45, marginBottom: 20 }}
              />
            )}
            {slideIndex > 6 && (
              <img
                src={`/images/khs-logo04.png`}
                style={{ width: 105, height: 30, marginBottom: 20 }}
              />
            )}

            <div className="description-wrapper">
              <div className="description">{description}</div>
            </div>
          </TabletCarouselItem>
        ))}
      </Carousel>
    );
  }

  _renderDots = () => {
    let { slideIndex } = this.props;
    let slides = [];

    for (let i = 0; i < 11; i++) {
      slides.push({ id: i });
    }

    return (
      <Widget.Row css="margin-bottom: 20px;">
        {slides.map((_, i) => (
          <Dot key={"dot" + i} color={slideIndex === i ? "white" : "#545454"} />
        ))}
      </Widget.Row>
    );
  };
}

const TabletCarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 20px 23px;
  max-width: 100%;
  width: 100%;
  height: 420px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;

  & .year {
    min-width: 150px;
    text-align: center;
    font-family: GothamBlack;
    font-size: 50px;
  }

  & .description {
    line-height: 15px;
    white-space: pre-line;
    font-family: GothamMedium;
    font-size: 13px;
  }

  & > .logo-square {
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
  }
  & > .logo-rectangle {
    margin-bottom: 30px;
    width: 80px;
    height: 28px;
  }

  :focus {
    outline: none;
  }

  @media screen and (max-width: 414px) {
    & > .description {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 360px) {
    min-height: 300px;
  }

  & .description-wrapper {
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    align-items: center;
    & .description {
      text-align: center;
    }
  }
`;

const Dot = styled.div`
  margin-right: 13px;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  background-color: ${props => props.color || "#545454"};

  :last-child {
    margin-right: 0px;
  }
`;

const Hint = styled.div`
  margin-bottom: 5px;
  font-family: GothamBold;
  font-size: 20px;
  color: #888888;
`;

export default AnniversaryTabletSmallCarousel;
