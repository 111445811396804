import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Settings from "../Domain/constants";
import { validateEmail } from "../Utils/FormUtil";
import QuizAnswerDetailItem from "./QuizAnswerDetailItem";
import * as Widget from "./Widget";
import * as Icon from "./Icon";
import "../global-styles";

const FacebookLogo =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhBwoKADWXdR8GAAAB7UlEQVRo3u2ZPUtcQRSGnzNYCKm8hQQLKyGkEhHZLcLWFmmSVNsFUmojFhbp/QE2FvkF619IsLDaZSFfkI8iRVYESZEriItI0GOxitndSbx3ZrxjMW85d957njNzZ+YyR/hLmtGgTo0lHhBefbp0aLMnufW5NjXXKpRrczx4pq1Kgl+rpdkgslwN/Qdm72DQ/6d9FiQHA8B25eFhlu2buY+lJohm/GCq8vwHOmLO0IgWHqZoGOrRwgPUDbWoADXRkzvZ9YqqL6qBX/mHd3xln+OR9ie8svYPuqxOdUOn7Vz60m6ZCJh7j+fyvqzJBAv/lsXy4cMBvGFZfrsYwwAcsCYXbtYwAOvSd7WGANiVHXezP8A5qz52f4Av8i0uwCc/u/9G9Hm8SWfY4mmxVP0BbCPwmhdF7f6H0UP5NZK/cMBMZQAioy06yWlxf7izwFEJoPwqeMb3W3qc8djq2wwD8FNuARC1IeqhvXf0KUgACSABRAcovxE9Gj4+5eNoBxXmLb5/XAKl4zgBJIAEkAASwD0AcL7dCaK+oRsVoGvoRAXoGNpRAdreJRuvH5Ij5ozkrETLf+WqhupTtBx/q04WtLZuLJn2KgfoDWqnBkByFvC4bnXQzqBuOkzuVLx2GIGh4vXQN+xSvi+xCqzl+0tKfT0KspeCNQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxNy0wNy0xMFQxMDowMDo1MyswMjowMJw2HIMAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTctMDctMTBUMTA6MDA6NTMrMDI6MDDta6Q/AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==";

class QuizResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      nationality: "",
      savePrivacyUS: false,
      savePrivacyNonUS: false,
      savePrivacyTaiwan: false,
      error: "",
      showAnswerDetails: false,
      showSuccess: false,
      showSpinner: false
    };
  }
  render() {
    let { language, data } = this.props;
    let {
      name,
      email,
      nationality,
      savePrivacyUS,
      savePrivacyNonUS,
      savePrivacyTaiwan,
      error,
      showAnswerDetails,
      showSuccess,
      showSpinner
    } = this.state;

    return (
      <Wrapper>
        {showSuccess ? (
          language === "en" ? (
            <>
              <h3>
                Thank You For Participating MAPEX 30th Anniversary Drum Kit
                Sweepstakes.
              </h3>
              <p>
                {
                  "Your participation form has been submitted to MAPEX successfully.\nDraw Date of the Sweepstakes: 2019 Oct. 1st (UTC+8)\nThe winner will be notified individually by email by October 5, 2019 (UTC+8).\nThe results of MAPEX Brand Knowledge Quiz have been sent to your mailbox.\n\nThank you for your participation."
                }
              </p>
            </>
          ) : (
            <>
              <h3>感謝您成功參與MAPEX週年限量鼓組抽獎活動!</h3>
              <p>
                {
                  "您參與抽獎的資訊已成功提交至MAPEX.\n正式抽獎日:2019年10月1日(UTC+8)\nMAPEX將再2019年10月5日(UTC+8)透過電子郵件通知幸運中獎粉絲.\n\nMAPEX 30週年品牌知識大考驗正確答案,已透過電子郵件方式寄送至您的信箱,請至您所屬信箱讀取信件!\n\nMAPEX再次感謝您的參與!!"
                }
              </p>
            </>
          )
        ) : showSpinner ? (
          <Widget.Spinner />
        ) : (
          <>
            <h3>
              {language === "en" ? data[0].end_title_en : data[0].end_title_tw}
            </h3>
            <h4>
              {language === "en"
                ? `You answered ${this._checkAnswers()} out of 10 questions correctly!!!`
                : `10 個問題中您答對了「 ${this._checkAnswers()} 」個問題！`}
            </h4>
            <p>
              {language === "en"
                ? data[0].end_description1_en
                : data[0].end_description1_tw}
            </p>
            <div className="form-fields">
              <InputField>
                <div>{language === "en" ? "Nick Name" : "暱稱"}</div>
                <input
                  value={name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </InputField>
              <InputField>
                <div>
                  {language === "en" ? "Country of Residential" : "居住地"}
                </div>
                <input
                  value={nationality}
                  onChange={e => this.setState({ nationality: e.target.value })}
                />
              </InputField>
              <InputField css="margin-bottom: 20px;">
                <div>{language === "en" ? "E-mail" : "電郵信箱"}</div>
                <input
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </InputField>
              <p style={{ fontSize: 10, marginBottom: 20 }}>
                {language === "en"
                  ? "* The winner will be notified individually by email by October 5, 2019 (UTC+8)."
                  : "* MAPEX將再2019年10月5日(UTC+8)透過電子郵件通知幸運中獎粉絲"}
              </p>
              <Widget.Row
                justify="space-between"
                align="center"
                extraCss="width: 100%; margin-bottom: 30px;"
              >
                <Widget.Row
                  align="center"
                  onClick={() =>
                    this.setState({ showAnswerDetails: !showAnswerDetails })
                  }
                >
                  <div
                    style={{
                      minWidth: 100,
                      color: "#FFFFFF",
                      cursor: "pointer"
                    }}
                  >
                    {language === "en" ? "QUIZ ANSWER" : "正確答案"}
                  </div>
                  {showAnswerDetails ? (
                    <Icon.KeyboardArrowUp color="#FFFFFF" />
                  ) : (
                    <Icon.KeyboardArrowDown color="#FFFFFF" />
                  )}
                </Widget.Row>
                <SocialIcon
                  src={FacebookLogo}
                  onClick={() => this._shareFBLink()}
                />
              </Widget.Row>
              {showAnswerDetails && (
                <div className="answer-details">
                  {data
                    .filter((_, i) => i > 0)
                    .map((d, i) => (
                      <QuizAnswerDetailItem
                        key={i}
                        language={language}
                        number={i + 1}
                        data={d}
                      />
                    ))}
                </div>
              )}
              {error && <div className="error">{error}</div>}
              <p>
                {language === "en"
                  ? data[0].end_description2_en
                  : data[0].end_description2_tw}
              </p>
              <Widget.Row align="center" extraCss="margin-bottom: 20px;">
                <div
                  onClick={() =>
                    this.setState({
                      savePrivacyUS: !savePrivacyUS,
                      savePrivacyNonUS: false,
                      savePrivacyTaiwan: false
                    })
                  }
                  style={{ marginRight: 10, cursor: "pointer" }}
                >
                  {savePrivacyUS ? (
                    <Icon.Checkbox
                      color="white"
                      style={{ width: 20, height: 20 }}
                    />
                  ) : (
                    <Icon.CheckboxOutline
                      color="white"
                      style={{ width: 20, height: 20 }}
                    />
                  )}
                </div>
                <span style={{ color: "white", fontSize: 14 }}>
                  As US resident, I agree to the 30th Anniversary Drum Kit
                  Sweepstakes
                  <a
                    style={{ color: "#bcc400", margin: "0px 5px" }}
                    href="https://drive.google.com/file/d/1mC9yKulftECeVXjKZX9_IQ5G8xUmhz0s/view"
                    target="_blank"
                  >
                    {`Terms & Disclaimer`}
                  </a>
                  and Administrator’s privacy policy.
                </span>
              </Widget.Row>
              <Widget.Row align="center" extraCss="margin-bottom: 20px;">
                <div
                  onClick={() =>
                    this.setState({
                      savePrivacyUS: false,
                      savePrivacyNonUS: !savePrivacyNonUS,
                      savePrivacyTaiwan: false
                    })
                  }
                  style={{ marginRight: 10, cursor: "pointer" }}
                >
                  {savePrivacyNonUS ? (
                    <Icon.Checkbox
                      color="white"
                      style={{ width: 20, height: 20 }}
                    />
                  ) : (
                    <Icon.CheckboxOutline
                      color="white"
                      style={{ width: 20, height: 20 }}
                    />
                  )}
                </div>
                <span style={{ color: "white", fontSize: 14 }}>
                  As Non-US resident, I agree to the 30th Anniversary Drum Kit
                  Sweepstakes
                  <a
                    style={{ color: "#bcc400", margin: "0px 5px" }}
                    href="https://drive.google.com/file/d/1QYS2kY5oajzeaU7Wan2laEGgT7Urd-O_/view"
                    target="_blank"
                  >
                    {`Terms & Disclaimer`}
                  </a>
                  and Administrator’s privacy policy.
                </span>
              </Widget.Row>
              <Widget.Row align="center" extraCss="margin-bottom: 30px;">
                <div
                  onClick={() =>
                    this.setState({
                      savePrivacyUS: false,
                      savePrivacyNonUS: false,
                      savePrivacyTaiwan: !savePrivacyTaiwan
                    })
                  }
                  style={{ marginRight: 10, cursor: "pointer" }}
                >
                  {savePrivacyTaiwan ? (
                    <Icon.Checkbox
                      color="white"
                      style={{ width: 20, height: 20 }}
                    />
                  ) : (
                    <Icon.CheckboxOutline
                      color="white"
                      style={{ width: 20, height: 20 }}
                    />
                  )}
                </div>

                <span style={{ color: "white", fontSize: 14 }}>
                  As Taiwan resident, I agree to the 30th Anniversary Drum Kit
                  Sweepstakes
                  <a
                    style={{ color: "#bcc400", margin: "0px 5px" }}
                    href="https://drive.google.com/file/d/1i6sJoDRwhx1hxXv0ca6lIx2uLlKJkDuv/view"
                    target="_blank"
                  >
                    {`Terms & Disclaimer`}
                  </a>
                  and Administrator’s privacy policy.
                </span>
              </Widget.Row>
              <p>
                {language === "en" ? (
                  <>
                    * If you have any questions about this privacy statement or
                    the practices described herein, you may contact us at
                    <a
                      style={{ color: "#bcc400", margin: "0px 5px" }}
                      href="http://mapexdrums.com/international/contact/"
                      target="_blank"
                    >
                      http://mapexdrums.com/international/contact/
                    </a>
                  </>
                ) : (
                  <>
                    * 如對於個人資料供MAPEX使用於30週年相關行銷活動有疑慮，請
                    <a
                      style={{ color: "#bcc400", margin: "0px 5px" }}
                      href="http://mapexdrums.com/international/contact/"
                      target="_blank"
                    >
                      聯繫我們
                    </a>
                    。
                  </>
                )}
              </p>
              <button onClick={this._submit}>SEND</button>
              )}
            </div>
          </>
        )}
      </Wrapper>
    );
  }

  _checkAnswers = () => {
    let { data, userAnswers } = this.props;

    let correct = [];

    for (let i = 0; i < userAnswers.length; i++) {
      if (userAnswers[i] === data[i + 1].answer_en) {
        correct.push("true");
      } else if (userAnswers[i] === data[i + 1].answer_tw) {
        correct.push("true");
      }
    }

    return correct.length;
  };

  _shareFBLink = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${Settings.webUrl}`
    );
  };

  _submit = () => {
    let { appActions, language } = this.props;
    let {
      name,
      email,
      nationality,
      savePrivacyUS,
      savePrivacyNonUS,
      savePrivacyTaiwan
    } = this.state;

    if (name === "") {
      return this.setState({ error: "name為必填！" });
    }

    if (email === "") {
      return this.setState({ error: "email為必填！" });
    }

    if (!validateEmail(email)) {
      return this.setState({ error: "請輸入正確的 email 格式！" });
    }

    if (!savePrivacyUS && !savePrivacyNonUS && !savePrivacyTaiwan) {
      return this.setState({ error: "請勾選是否同意讓我們保存您的私人資訊！" });
    }

    this.setState({ showSpinner: true, error: "" });
    appActions
      .createQuizRecord(name, email, nationality, language)
      .then(() => this.setState({ showSuccess: true }))
      .catch(err => {
        this.setState({ showSpinner: false });
        console.warn(err);
      });
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 960px;
  width: 100%;
  border: 1px solid white;
  padding: 50px 60px 60px;

  & > h3 {
    margin-bottom: 30px;
    white-space: pre-wrap;
    text-align: center;
    font-family: GothamBold;
    font-size: 30px;
    color: #bcc400;
  }

  & > h4 {
    margin-bottom: 15px;
    text-align: center;
    font-family: GothamBold;
    font-size: 24px;
    color: white;
  }

  & > p {
    margin-bottom: 50px;
    max-width: 680px;
    white-space: pre-wrap;
    line-height: 30px;
    font-family: GothamMedium;
    font-size: 16px;
    color: white;
  }

  & > .form-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 680px;
    width: 100%;
    font-family: GothamMedium;

    & > .answer-details {
      margin-bottom: 30px;
      width: 100%;
    }

    & > .error {
      padding: 15px;
      color: red;
    }

    & > button {
      z-index: 1;
      position: relative;
      font-size: inherit;
      font-family: GothamMedium;
      color: white;
      margin-bottom: 30px;
      padding: 0.5em 1em;
      outline: none;
      border: none;
      background-color: black;
      overflow: hidden;
      transition: color 0.4s ease-in-out;
      border: 1px solid white;
      min-width: 192px;
      min-height: 50px;
    }

    & > button::before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 100%;
      left: 100%;
      min-width: 192px;
      min-height: 50px;
      border-radius: 50%;
      background-color: white;
      transform-origin: center;
      transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
      transition: transform 0.45s ease-in-out;
    }

    & > button:hover {
      cursor: pointer;
      color: #161616;
    }

    & > button:hover::before {
      transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
    }

    & > p {
      margin-bottom: 40px;
      width: 100%;
      line-height: 30px;
      word-break: break-all;
      font-size: 16px;
      color: #bcc400;
    }
  }

  @media screen and (max-width: 550px) {
    padding: 50px 20px 60px;
  }

  @media screen and (max-width: 320px) {
    padding: 50px 10px 60px;
  }
`;

const InputField = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 20px 20px 10px;
  width: 100%;

  & > div {
    margin-right: 10px;
    min-width: 30px;
    max-width: 100px;
    color: white;
  }

  & > input {
    flex: 1;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #ffffff;
    padding: 3px;
    background-color: transparent;
    font-size: 16px;
    color: white;

    :focus {
      outline: none;
    }
  }

  :last-child {
    margin-right: 0px;
  }
  ${props => props.css}
`;

const SocialIcon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
  ${props => props.css}
`;

const TextField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;

  & > div {
    margin-right: 10px;
    width: 85px;
    color: white;
  }

  & > textarea {
    flex: 1;
    resize: none;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #ffffff;
    padding: 3px;
    background-color: #000000;
    font-size: 16px;
    color: white;

    :focus {
      outline: none;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(QuizResult);
