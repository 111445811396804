import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import Data from "../../content/quiz.json";
import * as Widget from "./Widget2";
import * as Icon from "./Icon";
import QuizItem from "./QuizItem";
import QuizResult from "./QuizResult";

let userAnswers = ["", "", "", "", "", "", "", "", "", ""];

class QuizSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      language: "",
      currentMaxSlides: 0,
      answerStatus: "",
      showAllAnswers: false,
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
    this._resizeCarouselHeight();
  }

  render() {
    let { slideIndex, language, currentMaxSlides, showAllAnswers } = this.state;

    return (
      <Wrapper id="quiz">
        <Widget.SectionTitle
          css={`
            margin-bottom: 60px;
            @media screen and (max-width: 414px) {
              margin-bottom: 20px;
            }
            @media screen and (max-width: 375px) {
              margin-bottom: 0px;
            }
          `}
        >
          QUIZ
        </Widget.SectionTitle>
        {showAllAnswers ? (
          <QuizResult
            language={language}
            data={Data}
            userAnswers={userAnswers}
          />
        ) : (
          <Carousel
            swiping={false}
            width={"100%"}
            speed={250}
            height={this._resizeCarouselHeight}
            slideIndex={slideIndex}
            renderCenterLeftControls={this._renderLeftIcon}
            renderCenterRightControls={this._renderRightIcon}
            renderBottomCenterControls={this._renderStatusHint}
            afterSlide={slideIndex => {
              if (currentMaxSlides < slideIndex) {
                this.setState({ currentMaxSlides: slideIndex });
              }
              this.setState({ slideIndex, answerStatus: "" });
            }}
          >
            {Data.map((d, i) => (
              <div className="content" key={"quiz-item" + i}>
                <QuizItem
                  data={d}
                  language={language}
                  slideIndex={slideIndex}
                  currentMaxSlides={currentMaxSlides}
                  setLanguage={language => this.setState({ language })}
                  onClick={() => {
                    if (language === "") {
                      return;
                    }
                    return this.setState({ slideIndex: 1 });
                  }}
                  updateAnswer={(boolean, value) => {
                    this.setState({ answerStatus: boolean });
                    userAnswers[currentMaxSlides - 1] = value;
                  }}
                  showAllAnswers={() => this.setState({ showAllAnswers: true })}
                />
              </div>
            ))}
          </Carousel>
        )}
      </Wrapper>
    );
  }

  _resizeCarouselHeight = () => {
    let { mounted } = this.state;

    if (mounted) {
      if (window.screen.width <= 550 && window.screen.width > 375) {
        return "850px";
      }

      if (window.screen.width <= 375) {
        return "800px";
      }
    }

    return "850px";
  };

  _renderLeftIcon = ({ previousSlide }) => {
    let { slideIndex } = this.state;

    return (
      <div style={{ cursor: "pointer" }} onClick={previousSlide}>
        <Icon.KeyboardArrowLeft
          color={slideIndex === 0 ? "#737373" : "white"}
          style={{ width: 40, height: 40 }}
        />
      </div>
    );
  };

  _renderRightIcon = ({ nextSlide }) => {
    let { slideIndex, language, currentMaxSlides, answerStatus } = this.state;

    if (slideIndex === 0) {
      return (
        <div
          style={{ cursor: "pointer" }}
          onClick={language ? nextSlide : () => 0}
        >
          <Icon.KeyboardArrowRight
            color={language ? "white" : "#737373"}
            style={{ width: 40, height: 40 }}
          />
        </div>
      );
    }

    if (slideIndex === 10) {
      return (
        <div style={{ cursor: "pointer" }}>
          <Icon.KeyboardArrowRight
            color="#737373"
            style={{ width: 40, height: 40 }}
          />
        </div>
      );
    }

    if (slideIndex < currentMaxSlides) {
      return (
        <div style={{ cursor: "pointer" }} onClick={nextSlide}>
          <Icon.KeyboardArrowRight
            color={"white"}
            style={{ width: 40, height: 40 }}
          />
        </div>
      );
    }

    if (slideIndex === currentMaxSlides) {
      return (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (answerStatus === "") {
              if (userAnswers[currentMaxSlides - 1]) {
                return nextSlide();
              }
              return () => 0;
            }
            return nextSlide();
          }}
        >
          <Icon.KeyboardArrowRight
            color={(() => {
              if (answerStatus === "") {
                if (userAnswers[currentMaxSlides - 1]) {
                  return "white";
                }
                return "#737373";
              }
              return "white";
            })()}
            style={{ width: 40, height: 40 }}
          />
        </div>
      );
    }

    return;
  };

  _renderStatusHint = () => {
    let { slideIndex } = this.state;
    let slides = [];
    for (let i = 0; i < 11; i++) {
      slides.push({ id: i });
    }
    return (
      <StatusHintContainer>
        {slides.map((_, i) => (
          <StatusHint
            key={"status-hint-item" + i}
            color={i === slideIndex ? "true" : ""}
            last={i === slides.length - 1}
          />
        ))}
      </StatusHintContainer>
    );
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 100px 36px 107px;
  width: 100%;
  min-height: 900px;

  & .content {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 0.5px solid white;
    margin: 0 auto;
    padding: 45px;
    width: 85%;
    height: 620px;

    :focus {
      outline: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 50px 20px;
  }

  @media screen and (max-width: 700px) {
    & .content {
      padding: 30px;
      height: 680px;
    }
  }

  @media screen and (max-width: 570px) {
    & .content {
      border: 0px;
      width: 100%;
      height: 800px;
    }
  }

  @media screen and (max-width: 340px) {
    & .content {
      height: 850px;
    }
  }
`;

const StatusHintContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 500px) {
    margin-bottom: 5px;
  }
`;

const StatusHint = styled.div`
  margin-right: 10px;
  width: ${props => (props.last ? 10 : 20)}px;
  height: ${props => (props.last ? 10 : 5)}px;
  border-radius: ${props => (props.last ? 50 : 0)}%;
  background-color: ${props => (props.color ? "white" : "#545454")};
`;

export default QuizSection;
