import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "./Icon";
import AnniversaryLargeCarousel from "./AnniversaryLargeCarousel";
import AnniversaryDesktopSmallCarousel from "./AnniversaryDesktopSmallCarousel";
import AnniversaryYearCarousel from "./AnniversaryYearCarousel";
import AnniversaryTabletSmallCarousel from "./AnniversaryTabletSmallCarousel";

class AnniversarySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0
    };
  }

  render() {
    let { slideIndex } = this.state;

    return (
      <Wrapper id="anniversary">
        <div className="large-carousel">
          <AnniversaryLargeCarousel
            slideIndex={slideIndex}
            afterSlide={slideIndex => this.setState({ slideIndex })}
          />
        </div>

        <div className="small-carousel">
          <div className="desktop">
            <AnniversaryDesktopSmallCarousel
              slideIndex={slideIndex}
              afterSlide={slideIndex => this.setState({ slideIndex })}
            />
          </div>
          <div className="tablet">
            <div className="menu">
              <AnniversaryTabletSmallCarousel
                slideIndex={slideIndex}
                onLeftArrowClick={() => {
                  if (slideIndex === 0) {
                    return this.setState({ slideIndex: 10 });
                  }
                  return this.setState({ slideIndex: slideIndex - 1 });
                }}
                onRightArrowClick={() => {
                  if (slideIndex === 10) {
                    return this.setState({ slideIndex: 0 });
                  }
                  return this.setState({ slideIndex: slideIndex + 1 });
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  & > .large-carousel {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  & > .small-carousel {
    & > .desktop {
      position: absolute;
      z-index: 2;
      left: 80px;
      bottom: 0px;

      max-width: 510px;
      width: 100%;
      min-height: 600px;
    }

    & > .tablet {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    & > .small-carousel {
      & > .desktop {
        left: 40px;
      }
    }
  }

  @media screen and (max-width: 850px) {
    & > .small-carousel {
      & > .desktop {
        display: none;
      }

      & > .tablet {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        height: 100vh;

        & > .toggle {
          display: flex;
          justify-content: center;
          align-items: center;

          margin-right: 60px;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          padding: 5px 8px;

          min-height: 200px;
          background-color: rgba(255, 255, 255, 0.8);
          writing-mode: vertical-rl;

          & > span {
            margin-bottom: 5px;
          }
        }

        & > .year-carousel {
          max-width: 300px;
          width: 100%;
        }

        & > .menu {
          position: relative;
          width: 100vw;
          z-index: 9;
          height: 420px;

          & > .close {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 3;
          }
        }
      }
    }
  }

  @media screen and (max-width: 414px) {
    & > .small-carousel {
      & > .tablet {
        bottom: 200px;

        & > .toggle {
          margin-right: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    & > .small-carousel {
      & > .tablet {
        bottom: 250px;
      }
    }
  }

  @media screen and (max-width: 360px) {
    & > .small-carousel {
      & > .tablet {
        bottom: 280px;

        & > .toggle {
          margin-right: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 320px) {
    & > .small-carousel {
      & > .tablet {
        bottom: 340px;
        & > .year-carousel {
          max-width: 250px;
        }
      }
    }
  }
`;

export default AnniversarySection;
