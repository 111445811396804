import React, { Component } from "react";
import styled from "styled-components";

class QuizAnswerDetailItem extends Component {
  render() {
    let { language, number, data } = this.props;

    if (language === "en") {
      return (
        <Wrapper>
          <div className="question">
            Q{number}. {data.question_en}
          </div>
          <div className="answer">* Correct Answer : {data.answer_en}</div>
          <div className="detail">
            {data.answer_detail_en &&
              ` * Explanation: \n${data.answer_detail_en}`}
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <div className="question">
          Q{number}. {data.question_tw}
        </div>
        <div className="answer">* 正解：{data.answer_tw}</div>
        <div className="detail">
          {data.answer_detail_tw && `* 說明： \n${data.answer_detail_tw}`}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin-bottom: 30px;
  width: 100%;
  line-height: 30px;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: white;

  & > .question {
    font-weight: bolder;
  }

  & > .answer {
    font-weight: 300;
  }

  & > .detail {
    font-weight: 300;
  }
`;

export default QuizAnswerDetailItem;
