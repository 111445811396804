import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import Data from "../../content/bgstory.json";
import * as Widget from "./Widget2";
import * as Icon from "./Icon";

class AnniversaryYearCarousel extends Component {
  render() {
    let { slideIndex, afterSlide } = this.props;
    return (
      <Wrapper>
        <h3>SNARE DRUM</h3>

        <Carousel
          swiping={false}
          width={"100%"}
          height={"130px"}
          slideIndex={slideIndex}
          afterSlide={afterSlide}
          renderBottomCenterControls={this._renderDots}
          renderCenterLeftControls={this._renderLeftArrowIcon}
          renderCenterRightControls={this._renderRightArrowIcon}
          renderBottomLeftControls={this._renderPreviousYear}
          renderBottomRightControls={this._renderNextYear}
        >
          {Data.map(({ year }, i) => (
            <YearItem>{year}</YearItem>
          ))}
        </Carousel>
      </Wrapper>
    );
  }

  _renderDots = () => {
    let { slideIndex } = this.props;
    let slides = [];

    for (let i = 0; i < 11; i++) {
      slides.push({ id: i });
    }

    return (
      <Widget.Row css="margin-bottom: 10px;">
        {slides.map((_, i) => (
          <Dot key={"dot" + i} color={slideIndex === i ? "white" : "#545454"} />
        ))}
      </Widget.Row>
    );
  };

  _renderLeftArrowIcon = ({ previousSlide }) => {
    let { slideIndex } = this.props;

    return (
      <IconContainer onClick={() => previousSlide()}>
        <Icon.KeyboardArrowLeft
          color={slideIndex === 0 ? "#545454" : "white"}
          style={{ width: 40, height: 40 }}
        />
      </IconContainer>
    );
  };

  _renderRightArrowIcon = ({ nextSlide, slideCount }) => {
    let { slideIndex } = this.props;

    return (
      <IconContainer onClick={() => nextSlide()}>
        <Icon.KeyboardArrowRight
          color={slideIndex === slideCount - 1 ? "#545454" : "white"}
          style={{ width: 40, height: 40 }}
        />
      </IconContainer>
    );
  };

  _renderPreviousYear = ({ slideCount }) => {
    let { slideIndex } = this.props;

    if (slideIndex === 0) {
      return <Hint>{Data[slideCount - 1].year}</Hint>;
    }

    return <Hint>{Data[slideIndex - 1].year}</Hint>;
  };

  _renderNextYear = ({ slideCount }) => {
    let { slideIndex } = this.props;

    if (slideIndex === slideCount - 1) {
      return;
    }

    return <Hint>{Data[slideIndex + 1].year}</Hint>;
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > h3 {
    font-weight: 500;
    font-size: 40px;
    color: white;
  }

  @media screen and (max-width: 414px) {
    & > h3 {
      font-size: 30px;
    }
  }
`;

const YearItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  font-weight: 600;
  font-size: 80px;
  color: white;

  :focus {
    outline: none;
  }

  @media screen and (max-width: 414px) {
    margin-top: 15px;
    font-size: 60px;
  }
`;

const Dot = styled.div`
  margin-right: 13px;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  background-color: ${props => props.color || "#545454"};

  :last-child {
    margin-right: 0px;
  }

  @media screen and (max-width: 550px) {
    margin-right: 10px;
  }

  @media screen and (max-width: 414px) {
    margin-right: 5px;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const Hint = styled.div`
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 20px;
  color: #545454;
`;

export default AnniversaryYearCarousel;
