import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "./Icon";

class QuizImgAnswerOption extends Component {
  render() {
    let { data, selected, onClick, idx } = this.props;
    return (
      <Wrapper onClick={() => onClick(data)}>
        {selected === data ? (
          <Icon.RadioButtonChecked
            color="#bfc23e"
            style={{ width: 25, height: 25 }}
          />
        ) : (
          <Icon.RadioButtonUnChecked
            color="white"
            style={{ width: 25, height: 25 }}
          />
        )}
        {idx === 0 && (
          <div>
            <img src={`/images/${data}`} style={{ width: 105, height: 30 }} />
          </div>
        )}
        {idx === 1 && (
          <div>
            <img src={`/images/${data}`} style={{ width: 127, height: 45 }} />
          </div>
        )}
        {idx === 2 && (
          <div>
            <img src={`/images/${data}`} style={{ width: 50, height: 45 }} />
          </div>
        )}
        {idx === 3 && <span>{data}</span>}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;

  & > div {
    margin-left: 20px;
    max-width: 250px;
    width: 100%;

    display: flex;
    align-items: center;
  }

  & > span {
    margin-left: 20px;
    max-width: 250px;
    width: 100%;
  }
`;

export default QuizImgAnswerOption;
