import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "./Widget2";
import ProductItem from "./ProductItem";

const Data = [
  {
    name: "SNARE DRUM",
    img: "photo01.jpg",
    detailLink:
      "https://drive.google.com/file/d/1_b202zqqhp0SDFH52Sa937mWhUlV6iB_/view",
    detailImg: [
      "photo01-01.jpg",
      "photo01-02.jpg",
      "photo01-03.jpg",
      "photo01-04.jpg",
      "photo01-05.jpg",
      "photo01-06.jpg",
      "photo01-07.jpg"
    ],
    detailText: [
      "The 30th Anniversary Limited Edition Snare Drum is a throwback tribute to the widely admired Deep Forest Snare Drum of the 1990’s. Made of the same natural finished 100% walnut plies, this drum’s Dark, Rich Saturating Tone is sure to awaken every drummer’s inspiration for a solid backbeat. The sound quality is defined by the SONIClear™ Bearing Edge, and focused by the Sonic Hoops making this a classic MAPEX snare drum with modern MAPEX character.",
      "",
      "SONICLEAR™ BEARING EDGE\n\nThe SONIClear™ Bearing Edge is a standard feature on all 30th Anniversary shells, allowing drum heads to sit flush on the drums, promoting ease of tuning, increased shell resonance, and optimal tonal clarity.",
      'SONIC SAVER™ HOOPS\n\nThe inward flange and extra weight of the Sonic Saver™ Hoops help to focus and control the sound of the drums. The projection is more "down" than "out" and will provide more of a "vintage" or closed tone.',
      'CYLINDER-DRIVE STRAINER AND BUTT-END ADJUSTER\n\nThe Cylinder-Drive Strainer and Butt-End Adjuster use a self-lubricating bearing to ensure smooth operation. The adjustment dial employs "micro-lock" technology that provides precise control and prevents the snare wires from loosening during play.',
      "PURESOUND® CUSTOM SERIES 16-STRAND WIRES\n\nThe Puresound® Custom Series 16-Strand Wires feature medium-gauge wire with evenly spaced standard coils, providing an even balance between snare response and shell sound."
    ]
  },
  {
    name: "DRUM KIT",
    img: "photo02.jpg",
    detailLink:
      "https://drive.google.com/file/d/18uoUbAPuFA9EBUXFZW12ezS00MO5jaU6/view",
    detailImg: [
      "photo02-01.jpg",
      "photo02-02.jpg",
      "photo02-03.png",
      "photo02-04.jpg",
      "photo02-05.jpg",
      "photo02-06.jpg",
      "photo02-07.jpg",
      "photo02-08.jpg",
      "photo02-09.jpg",
      "photo02-10.jpg",
      "photo02-11.jpg",
      "photo02-12.jpg",
      "photo02-13.jpg",
      "photo02-14.jpg",
      "photo02-15.jpg",
      // "photo02-16.jpg",
      "photo02-17.jpg"
    ],
    detailText: [
      "The MAPEX 30th Anniversary Limited Edition Drum Kit inherits the spirit of “Orion Classic” series drum kit, the most representative sound of MAPEX drums in the 90s. This special anniversary drum kit is an all-maple kit, made of 100% North American Maple wood like the original Orion Classic series. The sound characteristics of these drum shells provide Warmth, Presence, and Projection while the more modern features developed through the Mapex Design Lab enables the drum kit to produce an even warmer sound with minimum vibration transfer and maximum resonance.",
      "",
      "Hardware, cymbals and snare drum are not included in the Mapex 30th Anniversary drum set.",
      "Hardware, cymbals and snare drum are not included in the Mapex 30th Anniversary drum set.",
      'SAS FINISHES WITH DOUBLE VENEERED DESIGN\n\nBased on the proprietary "SAS Sonic Effect Scale" developed by MAPEX, the 30th Anniversary drum kit implements a double veneered design inside and out, which not only results in a stunning finish but also raises the shell’s fundamental pitch. Transparent Flame on the inside and Garnet Flame on the outside of the shell.',
      "SONICLEAR™ BEARING EDGE\n\nThe SONIClear™ Bearing Edge is a standard feature on all 30th Anniversary shells, allowing drum heads to sit flush on the drums, promoting ease of tuning, increased shell resonance, and optimal tonal clarity.",
      'SONIC SAVER™ HOOPS\n\nThe inward flange and extra weight of the Sonic Saver Hoops help to focus and control the sound of the drums. The projection is more "down" than "out" and will provide more of a "vintage" or closed tone.',
      "SAS STATIC 90° FLOOR TOM LEGS\n\nThe straighter the path to the floor, the more sound loss: the static 90° Design Lab leg allows the most possible resonance from the floor tom.",
      'SONIC PEDESTAL FOOT\n\nThe steel leg is prevented from contacting the ground by an internal cotter pin, allowing the floor tom to "float" on the elevated "spring" of the rubber foot, eliminating vibration transfer and maximizing resonance.',
      "Hardware, cymbals and snare drum are not included in the Mapex 30th Anniversary drum set.",
      "Hardware, cymbals and snare drum are not included in the Mapex 30th Anniversary drum set.",
      "Hardware, cymbals and snare drum are not included in the Mapex 30th Anniversary drum set.",
      "Hardware, cymbals and snare drum are not included in the Mapex 30th Anniversary drum set.",
      "Hardware, cymbals and snare drum are not included in the Mapex 30th Anniversary drum set.",
      // "Hardware, cymbals and snare drum are not included in the Mapex 30th Anniversary drum set.",
      "Hardware, cymbals and snare drum are not included in the Mapex 30th Anniversary drum set."
    ]
  }
];

class ProductSection extends Component {
  render() {
    return (
      <Wrapper id="product">
        <Widget.SectionTitle>{"LIMITED EDITION PRODUCTS"}</Widget.SectionTitle>
        <div className="container">
          {Data.map((d, i) => (
            <ProductItem
              key={"product-item" + i}
              index={i}
              data={d}
              css={`
                @media screen and (max-width: 565px) {
                  margin-bottom: 20px;
                }
              `}
            />
          ))}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 75px 20px 100px;
  width: 100%;

  & > .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1180px;
    width: 100%;
    position: relative;
  }

  @media screen and (max-width: 1024px) {
    padding: 75px 40px 100px;
  }

  @media screen and (max-width: 565px) {
    & > .container {
      min-height: 770px;
    }
  }

  @media screen and (max-width: 320px) {
    padding: 75px 25px 50px;
  }
`;

export default ProductSection;
