import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import Data from "../../content/bgstory.json";
import * as Icon from "./Icon";
import * as Widget from "./Widget2";
import "../global-styles";

class AnniversaryDesktopSmallCarousel extends Component {
  render() {
    let { slideIndex, afterSlide } = this.props;
    return (
      <Wrapper>
        <Carousel
          width={"100%"}
          height={"100%"}
          wrapAround={true}
          speed={250}
          renderCenterRightControls={() => false}
          renderCenterLeftControls={() => false}
          renderBottomCenterControls={this._renderBottomDots}
          renderBottomLeftControls={this._renderPreviousYearAndArrow}
          renderBottomRightControls={this._renderNextYearAndArrow}
          slideIndex={slideIndex}
          afterSlide={afterSlide}
        >
          {Data.map(({ year, description }, i) => {
            return (
              <div className="item" key={i}>
                <DesktopCarouselItem>
                  {slideIndex < 4 && (
                    <img
                      src={`/images/khs-logo.png`}
                      style={{ width: 80, height: 72, marginBottom: 30 }}
                    />
                  )}
                  {slideIndex >= 4 && slideIndex <= 6 && (
                    <img
                      src={`/images/khs-logo03.png`}
                      style={{ width: 202, height: 71, marginBottom: 20 }}
                    />
                  )}
                  {slideIndex > 6 && (
                    <img
                      src={`/images/khs-logo04.png`}
                      style={{ width: 191, height: 55, marginBottom: 20 }}
                    />
                  )}
                  <div className="description">{description}</div>
                  <div className="year">{year}</div>
                </DesktopCarouselItem>
              </div>
            );
          })}
        </Carousel>
      </Wrapper>
    );
  }

  _renderBottomDots = () => {
    let { slideIndex } = this.props;

    let slides = [];
    for (let i = 0; i < 11; i++) {
      slides.push({ id: i });
    }

    return (
      <Widget.Row css="margin-bottom: 5px;">
        {slides.map((_, i) => (
          <Dot key={"dot" + i} selected={slideIndex === i} />
        ))}
      </Widget.Row>
    );
  };

  _renderPreviousYearAndArrow = ({ previousSlide, slideCount }) => {
    let { slideIndex } = this.props;

    return (
      <IconContainer onClick={previousSlide}>
        <Icon.KeyboardArrowLeft
          color={"white"}
          style={{ width: 50, height: 50 }}
        />
        <YearItem>
          {slideIndex === 0
            ? Data[slideCount - 1].year
            : Data[slideIndex - 1].year}
        </YearItem>
      </IconContainer>
    );
  };

  _renderNextYearAndArrow = ({ nextSlide, slideCount }) => {
    let { slideIndex } = this.props;

    return (
      <IconContainer onClick={nextSlide}>
        <Icon.KeyboardArrowRight
          color={"white"}
          style={{ width: 50, height: 50 }}
        />
        <YearItem>
          {slideIndex !== 10 ? Data[slideIndex + 1].year : Data[0].year}
        </YearItem>
      </IconContainer>
    );
  };
}

const Wrapper = styled.div`
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const DesktopCarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 5px;
  padding: 20px;

  max-width: 360px;
  width: 100%;
  height: 600px;
  background-color: rgba(0, 0, 0, 0.3);

  & > .logo-rectangle {
    margin-bottom: 30px;
    width: 80px;
    height: 28px;
  }

  & > .description {
    flex: 1;
    line-height: 20px;
    white-space: pre-line;
    font-family: GothamMedium;
    font-size: 14px;
    color: white;
  }

  & > .year {
    font-family: GothamBlack;
    font-size: 100px;
    color: white;
  }

  :focus {
    outline: none;
  }
`;

const Dot = styled.div`
  margin-right: 16px;
  margin-bottom: 10px;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background: ${props => (props.selected ? "white" : "#545454")};

  :last-child {
    margin-right: 0px;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const YearItem = styled.div`
  margin: 0px 5px 50px 5px;
  text-shadow: -2px 3px 4px #333333;
  font-family: GothamBold;
  font-size: 26px;
  color: #888888;
`;

export default AnniversaryDesktopSmallCarousel;
